import React from 'react'
import { NavBarScreen } from '../navBar/NavBarScreen'

export const TigerClub = () => {
    return (
        <>
            <NavBarScreen />

            <div className="cont_img animate__animated  animate__fadeIn" >
                <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
                    <div className="carousel-inner" role="listbox">

                        <div className="banner" data-bs-interval="4000" style={{ backgroundImage: `url('./assets/images/principal/cueva/b.jpeg')` }}>
                            <div className="pantalla_dividida" style={{ height: "45vh" }}>
                                <div className="text_cent_img_dividida animate__animated animate__fadeInRight animate__delay-0.8s">
                                    <h1 className="tit-sob-img_dividida" style={{ textShadow: '1px 1px 2px rgba(0,0,0,1.5)', letterSpacing: '0px' }}>Explora la<br />Cueva de los Tayos </h1>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <br></br>
            <br></br>
            <br></br>

            <div className='container'>
                <h1 className="camping-letters text-center" >TIGER CLUB</h1>
                <h4 className="camping-letters text-center" >Un espacio para quienes disfrutan la aventura.</h4>
            </div>

            <div className='container pantalla-grande'>
                <br></br>
                <div className='row'>
                    <div className='col-12 col-sm-12 col-md-6'>
                        <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item carousel-item2 active">
                                    {/* <img src={`${ApiStorage + tour.img_1}`} style={{ width: '100%' }} className="card-img-top" alt="..."></img> */}
                                    <img src={`./assets/images/principal/cueva/ct1.png`} className="rounded img-fluid img-thumbnail" alt="" width="100%" height="auto" />

                                </div>
                                <div className="carousel-item carousel-item2 ">
                                    {/* <img src={`${ApiStorage + tour.img_2}`} style={{ width: '100%' }} className="card-img-top" alt="..."></img> */}
                                    <img src={`./assets/images/principal/cueva/ct2.png`} className="rounded img-fluid img-thumbnail" alt="" width="100%" height="auto" />

                                </div>
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                    <div className='col-12 col-sm-12 col-md-6'>
                        <br></br>
                        <div>

                        </div>



                        <p className='text-justify text-center'>
                            Suscribete al Tyger Club y disfruta de todos los beneficios de ser parte de la manada
                        </p>
                        <p className='text-justify text-center'><b className='text-justify '>¿Qué te espera?</b></p>
                        <div>
                            <ul>
                                <li>Descuentos en alquiler de equipos: Al ser miembro de Tiger Club recibes un descuento del 10% en cualquiera de los equipos de camping que tenemos</li>
                                <li>Descuentos en la compra de productos. Queremos que estés equipado con el mejor equipo para tus rutas, así que recibes un 10% de descuento en todos los productos que tenemos a la venta para ti</li>
                                <li>Descuentos en Tours. En cualquiera de nuestras próximas rutas obtendrás un 10% de descuento</li>
                                <li>Descuentos con nuestros aliados estratégicos: Tenemos varios aliados que se han sumado a nuestro grupo. Te comentamos quienes son</li>
                                <li>Estás de cumpleaños? Tu tour tendrá un 50% de descuento para ti y un acompañante, es decir, viajan dos y el cumpleañero no paga</li>
                                <li>Kit de Bienvenida: Gorra, Taza, Tomatodo, Llavero, Agenda, Mochila Pequeña</li>
                            </ul>
                            <p>Inscríbete por 35 dólares</p>
                            <p>Mensualidad de 10.00</p>
                            <p>Nuestros Clientes Que han confiado en nuestro Trabajo y han Viajado más de 5 Veces con nosotros Ya son parte del Tiger Club.</p>
                            <p>Cuando no han pagado su membresía, su código QR deja de funcionar hasta que lo activen de nuevo cancelando la membresía del mes.</p>


                        </div>

                        <br></br>
                        <div className='text-justify text-center'>
                            <a href="https://wa.link/rataqq" target="_blank" style={{ textDecoration: 'none' }}>
                                <button type="button" style={{ width: "50%", margin: "5px" }} className="btn btn-success">
                                    <b>CLIC AQUÍ PARA MÁS INFO<i className="fab fa-whatsapp fa-1x"  ></i></b>
                                </button>
                            </a>
                        </div>


                        <br></br>


                        <br></br>
                        
                    </div>
                </div>

                <div className='container'>
                    <h3 className='camping-letters text-center'>HOSPEDAJE</h3>
                    <ul>
                        <li>Vista Hermosa</li>
                        <li>La estación</li>
                        <li>Urku Ñan</li>
                        <li>Urku Puñay</li>
                    </ul>

                    <h3 className='camping-letters text-center'>ALIMENTACIÓN</h3>
                    <ul>
                        <li>Fabricio Chef</li>
                    </ul>

                    <h3 className='camping-letters text-center'>DIVERSIÓN y COCTELERÍA</h3>
                    <ul>
                        <li>Rayuela Bar and Coffee</li>
                    </ul>

                    <h3 className='camping-letters text-center'>VESTIMENTA Y ACCESORIOS</h3>
                    <ul>
                        <li>Tribu en Sucursal Riobamba Sector Media Laguna</li>
                    </ul>

                    <h3 className='camping-letters text-center'>NUTRICIÓN</h3>
                    <ul>
                        <li>Omnilife Distribuidor Sector del Redondel del Libro</li>
                    </ul>

                    <h3 className='camping-letters text-center'>SALUD</h3>
                    <ul>
                        <li>Fisio Nature</li>
                    </ul>

                    <h3 className='camping-letters text-center'>TRANSPORTE PRIVADO</h3>
                    <ul>
                        <li>10% de descuento en viajes dentro y fuera de la ciudad para tus rutas</li>
                    </ul>

                    <h3 className='camping-letters text-center'>TECNOLOGÍA</h3>
                    <ul>
                        <li>Descuento en Payfer</li>
                    </ul>
                </div>

                <br></br>
                <br></br>
                <br></br>
            </div>


        </>
    )
}
